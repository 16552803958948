<template>
    <div class="main">
        <div>
            <embed class="pdf" id="pdv" :src="pdfUrl" width="800" height="500">
        </div>
        <div class="transaction-list">
            <h1 class="title">Inspection Report</h1>
            <div class="row">
                <div class="container">
                    <div class="form-group">
                        <label for="partyname">Party *</label>
                        <select id="partyname" v-model="formData.partyname">
                            <option value="0" selected>Select Party</option>
                            <option v-for="applicant in party" :key="applicant.applicantid"
                                :value="applicant.short_name">
                                {{ applicant.short_name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="vesselName">Vessel *</label>
                        <select id="vesselName" v-model="formData.vesselName">
                            <option value="0" selected>Select Vessel</option>
                            <option v-for="vessel in vessel" :key="vessel.Vesselid" :value="vessel.Vesselid">
                                {{ vessel.Name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="voyageName">Voyage *</label>
                        <select id="voyageName" v-model="formData.voyageName">
                            <option value="0" selected>Select Voyage</option>
                            <option v-for="voyage in voyage" :key="voyage.Voyageid" :value="voyage.Voyageid">
                                {{ voyage.Name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="locationname">Location *</label>
                        <select id="locationname" v-model="formData.locationname">
                            <option value="0" selected>Select Location</option>
                            <option v-for="location in location" :key="location.locationid"
                                :value="location.locationid">
                                {{ location.Name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="movementname">Movement Type *</label>
                        <select id="movementname" v-model="formData.movementname">
                            <option value="0" selected>Select Movement</option>
                            <option v-for="movement in movement" :key="movement.Movid" :value="movement.Movid">
                                {{ movement.Name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="fromdate">Activity From Date *</label>
                        <input type="date" id="fromdate" v-model="formData.fromdate"
                            :max="new Date().toLocaleDateString('en-ca')" />
                    </div>
                    <div class="form-group">
                        <label for="todate">Activity To Date *</label>
                        <input type="date" id="todate" v-model="formData.todate"
                            :max="new Date().toLocaleDateString('en-ca')" />
                    </div>
                    <div class="form-group">
                        <label for="contnumber">Container No</label>
                        <input type="text" id="contnumber" v-model="formData.contnumber"
                            placeholder="Generating Report for Specific Container(s)..." />
                    </div>
                    <div class="form-group">
                        <button type="button" class="excel-button" @click="getInspectionDetails">Show Result</button>
                        <button type="button" class="cancel-button1">Cancel</button>
                    </div>
                </div>
            </div>
            <div v-if="isLoading">Loading transactions...</div>
            <div v-else-if="error">Error loading transactions: {{ error }}</div>

            <div v-else>
                <div class="controls">
                    <div class="search-container">
                        <input v-model="searchQuery" placeholder="Search..." />
                        <i class="fas fa-search search-icon"></i>
                    </div>
                    <div class="actions">
                        <div>
                            Report Date: <input type="date" id="rptdate" v-model="rptdate"
                                :max="new Date().toLocaleDateString('en-ca')" />
                        </div>
                        <div style="margin-left: 5px;">
                            Report Type: <select id="rpttype" v-model="rpttype">
                                <option value="1">Survey</option>
                                <option value="2">Comparator</option>
                                <option value="3">Non-Eor</option>
                            </select>
                        </div>
                        <div>
                            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                                <button class="print-button" title="Print" @click="generateReport">
                                    <i class="fas fa-print"></i>Print
                                </button>
                            </div>
                        </div>
                        <button class="export-button" title="Export to CSV">
                            <i class="fas fa-ellipsis-v"></i>
                            <ul class="export-options">
                                <li @click="exportToCSV">Export to CSV</li>
                            </ul>
                        </button>
                    </div>
                </div>

                <div class="table-container">
                    <table class="transaction-table">
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" @change="selectAll">
                                </th>
                                <th>
                                    <span>Container</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('contnumber')"></i>
                                </th>
                                <th style="width:20px">
                                    <span>ISO</span>
                                </th>
                                <th>
                                    <span>Movement</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('MovementName')"></i>
                                </th>
                                <th>
                                    <span>Place</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('LocationName')"></i>
                                </th>
                                <th>
                                    <span>Date</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('inspDate')"></i>
                                </th>
                                <th>
                                    <span>Applicant</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('applicantname')"></i>
                                </th>
                                <th>
                                    <span>Account</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('accountname')"></i>
                                </th>
                                <th>
                                    <span>Vessel</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('VesselName')"></i>
                                </th>
                                <th>
                                    <span>Voyage</span>
                                    <i class="filter-icon fas fa-sort" @click="sortByColumn('VoyageName')"></i>
                                </th>
                                <!-- Add more columns as needed -->
                                <th v-if="hasPermissions">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction) in paginatedTransactions" :key="transaction.reportid"
                                :class="{ 'editing': transaction === selectedTransaction }">
                                <td>

                                    <input type="checkbox" v-model="checkedIds" :value="transaction.reportid">
                                </td>

                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.contnumber" placeholder="Container Number" />
                                </td>
                                <td v-else>{{ transaction.contnumber }}</td>
                                <td style="width:20px" v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.iso_code" placeholder="ISO" />
                                </td>
                                <td style="width:20px" v-else>{{ transaction.iso_code }}</td>
                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.MovementName" placeholder="Movement Name" />
                                </td>
                                <td v-else>{{ transaction.MovementName }}</td>
                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.LocationName" placeholder="Inspection Place" />
                                </td>
                                <td v-else>{{ transaction.LocationName }}</td>
                                <td>{{ transaction.inspdate }}</td>
                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.applicantname" placeholder="Applicant Name" />
                                </td>
                                <td v-else>{{ transaction.applicantname }}</td>
                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.accountname" placeholder="Account Name" />
                                </td>
                                <td v-else>{{ transaction.accountname }}</td>
                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.VesselName" placeholder="Vessel Name" />
                                </td>
                                <td v-else>{{ transaction.VesselName }}</td>
                                <td v-if="transaction === selectedTransaction">
                                    <input v-model="newTransaction.VoyageName" placeholder="Voyage Name" />
                                </td>
                                <td v-else>{{ transaction.VoyageName }}</td>
                                <!-- Add more fields as needed -->
                                <td v-if="hasPermissions">
                                    <button v-if="transaction === selectedTransaction" @click="saveTransaction"
                                        class="save-button"><i class="fas fa-save"></i></button>
                                    <button v-if="transaction === selectedTransaction" @click="cancelEdit"
                                        class="cancel-button"><i class="fas fa-times"></i></button>
                                    <button v-else @click="editTransaction(transaction)" class="edit-button"><i
                                            class="fas fa-edit"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="bottom-controls">
                    <div class="rows-per-table">
                        <button v-for="option in [10, 20, 30]" :key="option"
                            :class="{ 'active': rowsPerPage === option }" @click="setRowsPerPage(option)">
                            {{ option }}
                        </button>
                    </div>
                    <div class="pagination">
                        <button @click="goToPage(page)" v-for="page in totalPages" :key="page"
                            :class="{ 'active': currentPage === page }" class="page-number">{{ page }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <canvas ref="pdfCanvas" />
    </div>
</template>


<script>
import axios from 'axios';
//import VuePdfEmbed from 'vue-pdf-embed'
//import pdfjsLib from 'pdfjs-dist';

export default {
    data() {
        return {
            //pdfUrl: 'https://example.com/path/to/your.pdf', // Replace with your PDF URL
            transactions: [],
            party: [],
            vessel: [],
            voyage: [],
            location: [],
            movement: [],
            rptdate: new Date().toLocaleDateString('en-ca'),
            rpttype: 1,
            checkedIds: [],
            formData: {
                applicantid: '0',
                accountid: '0',
                partyname: '0',
                vesselName: '0',
                voyageName: '0',
                locationname: '0',
                movementname: '0',
                fromdate: '',
                todate: '',
                contnumber: '',
            },
            nameError: '',
            isLoading: false,
            error: null,
            hasPermissions: false,
            searchQuery: '',
            rowsPerPage: 10,
            currentPage: 1,
            selectedTransaction: null,
            newTransaction: {
                contnumber: '',
                contsize: '',
                conttype: '',
                TypeText: '',
                construction: '',
                iso_code: '',
                isotarewt: '',
                isogrosswt: '',
                applicantname: '',
                accountname: '',
                VesselName: '',
                Colsign: '',
                VoyageName: '',
                MovementName: '',
                LocationName: '',
            },
            sortDirection: {
                contnumber: 'asc',
                contsize: 'asc',
                conttype: 'asc',
                TypeText: 'asc',
                construction: 'asc',
                iso_code: 'asc',
                isotarewt: 'asc',
                isogrosswt: 'asc',
                applicantname: 'asc',
                accountname: 'asc',
                VesselName: 'asc',
                Colsign: 'asc',
                VoyageName: 'asc',
                MovementName: 'asc',
                LocationName: 'asc',
            },
            showAddTooltip: false,
        };
    },
    created() {
        this.fetchDropdownData()
        this.checkPermissions();
    },
    computed: {
        resetTransaction() {
            return {
                contnumber: '',
                contsize: '',
                conttype: '',
                TypeText: '',
                construction: '',
                iso_code: '',
                isotarewt: '',
                isogrosswt: '',
                applicantname: '',
                accountname: '',
                VesselName: '',
                Colsign: '',
                VoyageName: '',
                MovementName: '',
                LocationName: '',
            };
        },
        filteredTransactions() {
            if (!this.transactions) {
                return
            }
            return this.transactions.filter(transaction => {
                return Object.values(transaction).some(value =>
                    String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
                );
            }).sort((a, b) => {
                for (const key in this.sortDirection) {
                    if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
                    if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
                }
                return 0;
            });
        },
        paginatedTransactions() {
            if (!this.transactions) {
                return
            }
            const start = (this.currentPage - 1) * this.rowsPerPage;
            const end = start + this.rowsPerPage;
            return this.filteredTransactions.slice(start, end);
        },
        totalPages() {
            if (!this.transactions) {
                return
            }
            return Math.ceil(this.filteredTransactions.length / this.rowsPerPage);
        },
        //pdfUrl(pdfBlob){
        //    return pdfBlob
        //},
    },
    methods: {
        //async loadpdf() {
        //    pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';
        //    const loadingTask = pdfjsLib.getDocument(this.pdfUrl);
        //    this.pdfDoc = await loadingTask.promise;
        //},
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
        },
        showTooltip() {
            this.showAddTooltip = true;
        },
        hideTooltip() {
            this.showAddTooltip = false;
        },
        updateNewContainerIds(value) {
            const selectedParty = this.party.find(party => party.short_name === value);
            if (selectedParty) {
                this.formData.applicantid = selectedParty.applicantId;
                this.formData.accountid = selectedParty.accountId;
            } else {
                this.formData.applicantid = 0;
                this.formData.accountid = 0;
                console.log('hello')
            }
        },
        selectAll(event) {
            this.checkedIds = event.target.checked
                ? this.paginatedTransactions.map(transaction => transaction.reportid)
                : [];
        },


        async checkTransactionName(name) {
            if (name.trim() === '') {
                this.nameError = '';
                return;
            }

            try {
                const transactionExists = this.transactions.some(transaction => transaction.contnumber.toLowerCase() === name.toLowerCase());
                if (transactionExists) {
                    this.nameError = 'Transaction name already exists.';
                } else {
                    this.nameError = '';
                }
            } catch (err) {
                console.error('Error checking transaction name:', err);
            }
        },
        async getInspectionDetails() {
            this.isLoading = true;
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            this.updateNewContainerIds(this.formData.partyname)
            const transactionData = {
                applicant: this.formData.applicantid,
                account: this.formData.accountid,
                vessel: this.formData.vesselName,
                voyage: this.formData.voyageName,
                movement: this.formData.movementname,
                location: this.formData.locationname,
                fromdate: this.formData.fromdate,
                todate: this.formData.todate,
                contnumber: this.formData.contnumber
            }
            if (transactionData.location == "") {
                transactionData.location = 0;
            }
            try {
                const response = await axios.get('/get_inspection?applicant=' + transactionData.applicant + '&account=' + transactionData.account + '&vessel=' + transactionData.vessel + '&voyage=' + transactionData.voyage + '&movement=' + transactionData.movement + '&location=' + transactionData.location + '&fromdate=' + transactionData.fromdate + '&todate=' + transactionData.todate + '&contnumber=' + transactionData.contnumber, config);
                console.log(response.data[1], response.data[2]);
                this.transactions = response.data[2];
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                this.isLoading = false;
            }
        },
        checkPermissions() {
            const user = JSON.parse(localStorage.getItem('user'));
            this.hasPermissions = user && user.role === 'admin';
        },
        editTransaction(transaction) {
            this.selectedTransaction = transaction;
            this.newTransaction = { ...transaction };
        },
        cancelEdit() {
            this.selectedTransaction = null;
            this.newTransaction = { ...this.resetTransaction };
        },
        resetFilters() {
            this.formData = {
                applicantid: '0',
                accountid: '0',
                partyname: '0',
                vesselName: '0',
                voyageName: '0',
                locationname: '0',
                movementname: '0',
                fromdate: '',
                todate: '',
                contnumber: '',
            };
            this.checkedIds = [];
            this.currentPage = 1;
            this.searchQuery = '';
        },
        async generateReport() {
            const rptdate = this.rptdate;
            const rpttype = this.rpttype;
            const checkedIds = this.checkedIds;
            alert(rpttype)
            if (checkedIds == "") {
                alert('Please select an Inspection');
                return;
            } else if (rptdate == "") {
                alert('Please insert a report date');
                return;
            } else if (rpttype == "") {
                alert('Please select a eeport Type');
                return;
            }
            //alert(rpttype + "-hi-" + rptdate + "-" + checkedIds);
            try {
                const token = localStorage.getItem('access_token');
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await axios.get('/generate_report?reportid=' + checkedIds + '&rptdate=' + rptdate + '&rpttype=' + rpttype,config,{responseType:'blob'})
                const pdfBlob = new Blob([response.data], { type: "application/pdf" });
                const url = URL.createObjectURL(pdfBlob);
                window.open(url);
                console.log('File uploaded successfully:', response.data);
                //this.transactions = [];

            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchByteArray(filePath) {
            // to see the "loading..." effect, I intentionally add a "setTimeout" code. that loads the pdf after "3s". You can remove it in your real app.
            await new Promise(resolve => setTimeout(resolve, 3000));
            fetch(filePath)
                .then(response => {
                    console.log(response);
                    // for this case "response.url" is what we need, but if you fetch data for example from a database ... you may need "response.json()" or other codes;
                    this.srcData = response.url;
                    this.byteArrayPDF = true;
                })
        },
        exportToCSV() {
            // Define the header columns
            const headers = [
                "reportid", "typeid", "containerId", "contnumber", "contsize", "conttype", "TypeText",
                "construction", "iso_code", "isotarewt", "isogrosswt", "applicant", "applicantname",
                "account", "accountname", "vesselld", "VesselName", "Colsign", "voyage", "VoyageName",
                "movementId", "MovementName", "inspectionplace", "LocationName", "seal", "slot", "inspdate",
                "insptime", "tolocation", "tolocationname", "loadport", "pol", "destnport", "pod", "transport",
                "fpd", "shifts", "conditionid", "condition", "c_agentid", "note", "transporter", "tranmode",
                "cargowt", "hazstat", "joborderno", "bookingrelno", "unno", "temp", "stowloc",
                "transporterNM", "SBno", "SBdate", "isrpt", "rptno", "rptdate", "isbilled", "billno",
                "billdate", "Rate", "isDownloaded", "isCodecoDownloaded", "is_ovi_download", "filename",
                "FileDateTime", "fileDatetimeStr", "isApp", "companyId", "created_by", "CreateDate",
                "ModifiedBy", "ModiDateTime"
            ];

            // Create CSV content
            const csvContent = [
                headers.join(","), // Join header columns with commas
                ...this.transactions.map(transaction =>
                    headers.map(header => JSON.stringify(transaction[header] || '')).join(",") // Map each transaction to the header columns
                )
            ].join("\n");

            // Encode CSV content
            const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "transactions.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up by removing the link element
        },
        setRowsPerPage(option) {
            this.rowsPerPage = option;
        },
        goToPage(page) {
            this.currentPage = page;
        },
        sortByColumn(column) {
            this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
        },
        async fetchDropdownData() {
            try {
                const token = localStorage.getItem('access_token');
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                const partyRes = await axios.get('/linkParties', config);
                console.log(partyRes.data[2]);
                this.party = partyRes.data[2];

                const vessRes = await axios.get('/vessel_list', config);
                console.log(vessRes.data[2]);
                this.vessel = vessRes.data[2];

                const voyRes = await axios.get('/voyage_list', config);
                console.log(voyRes.data[2]);
                this.voyage = voyRes.data[2];

                const locRes = await axios.get('/location_list', config);
                console.log(locRes.data[2]);
                this.location = locRes.data[2];

                const moveRes = await axios.get('/movement_list', config);
                console.log(moveRes.data[2]);
                this.movement = moveRes.data[2];
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        },
    },
};
</script>

<style scoped>
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

}

.container {
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
    width: 22%;
    padding: 5px;
    float: left;
}

label {
    display: block;
    margin-bottom: 5px;
}

select,
input[type="text"],
input[type="date"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input::placeholder {
    color: #888;
}

.excel-button,
.generate-button,
.cancel-button1 {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.excel-button {
    background-color: #17a2b8;
}

.cancel-button1 {
    background-color: #dc3545;
}

button:hover {
    opacity: 0.9;
}

.date-range {
    display: flex;
    justify-content: space-between;
}

.date-range label {
    flex: 1;
    margin: 0 10px;
}

.date-range input {
    flex: 2;
}





.main {
    background-color: #f0f2f5;
    font-family: 'Roboto', sans-serif;
}



.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: visible;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position above the button */
    left: 50%;
    margin-left: -60px;
    /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
    opacity: 1;
}

.transaction-list {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    color: #333;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.search-container {
    position: relative;
}

.search-container input {
    padding: 8px 30px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.error-message {
    color: #d9534f;
    /* Bootstrap's danger color */
    font-size: 12px;
    margin-bottom: 4px;
    /* Space between the error message and input field */
    padding: 4px;
    border: 1px solid #d9534f;
    border-radius: 4px;
    background-color: #f9d6d5;
    /* Light red background */
    display: inline-block;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #666;
    cursor: pointer;
}

.actions {
    display: flex;
    align-items: center;
}

.print-button {
    background-color: rgb(50, 89, 187);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.print-button:hover {
    background-color: rgb(61, 146, 231);
}

.export-button {
    background-color: rgb(50, 89, 187);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    margin-left: 10px;
}

.export-button:hover {
    background-color: rgb(61, 146, 231);
}

.export-options {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: left;
    top: 40px;
    border-radius: 10px;
}

.export-button:hover .export-options {
    display: block;
}

.rows-per-table {
    display: flex;
    align-items: center;
}

.rows-per-table button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 14px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.rows-per-table button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}

.export-options li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
}

.export-options li:hover {
    background-color: #ddd;
}

.table-container {
    max-height: 100%;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.transaction-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.transaction-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.transaction-table th .filter-icon {
    cursor: pointer;
    margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
    color: green;
}

.cancel-button {
    color: red;
}

.new-row input {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.rows-per-table {
    display: flex;
    align-items: center;
}

.rows-per-table button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 14px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.rows-per-table button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.pagination button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333;
    font-size: 15px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.pagination button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}
</style>