<template>
  <div class="body-content">
    <h1 class="title">Dashboard</h1>
  </div>
</template>


<script>

</script>


<style scoped>
.dashboard {
  padding: 20px;
}

.widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
