<template>
  <div class="page-container">
    <h2 class="name">MIS Report</h2>

    <!-- Top Edit MIS Bill Section -->
    <div class="top-edit-section">
      <!-- Left Side for Edit MIS Bill -->
      <div class="left-side">
        <!-- Form Fields -->
        <div class="row first-row">
          <div class="field">
            <label for="date">Date:</label>
            <input v-model="date" type="date" id="date" class="input-box" />
          </div>
          <div class="field">
            <label for="applicant">Applicant:</label>
            <v-select v-model="selectedApplicant" :options="applicants" label="applicantname"
              :reduce="applicant => applicant.applicantid" placeholder="Select Applicant" class="input-box"
              :clearable="false"></v-select>
          </div>
          <div class="field">
            <label for="account">Account:</label>
            <v-select v-model="selectedAccount" :options="accounts" label="accountname"
              :reduce="account => account.accountid" placeholder="Select Account" class="input-box"
              :clearable="false"></v-select>
          </div>
        </div>

        <div class="row second-row">
          <div class="field">
            <label for="customer-ref">Customer Ref NO:</label>
            <input v-model="customerRef" type="text" id="customer-ref" class="input-box" />
          </div>
          <div class="field checkbox-field center-checkbox">
            <label for="report-in-bill">Report in BILL:</label>
            <input v-model="reportInBill" type="checkbox" id="report-in-bill" />
          </div>
          <div class="field">
            <label for="report-date">Report Date:</label>
            <input v-model="reportDate" type="date" id="report-date" class="input-box" />
          </div>
          <div class="field checkbox-field center-checkbox">
            <label for="round-off-checkbox">Round Off:</label>
            <input v-model="roundOffChecked" type="checkbox" id="round-off-checkbox" />
          </div>

        </div>

        <div class="row third-row">
          <div class="field">
            <label for="particulars">Particulars:</label>
            <textarea v-model="particulars" id="particulars" class="particulars-box"></textarea>
          </div>
        </div>

        <hr class="separator" />

        <div class="row non-gst-row">
          <div class="field checkbox-field center-checkbox center-non">
            <label for="non-gst-bill">Non-GST Bill:</label>
            <input v-model="nonGstBill" type="checkbox" id="non-gst-bill" />
            <label for="exp-bill">Exp Bill:</label>
            <input v-model="isExpBill" type="checkbox" id="exp-bill" />
          </div>
        </div>

        <!-- Product Details Table -->
        <div class="table-section">
          <h4>Product Details</h4>
          <table class="details-table">
            <thead>
              <tr>
                <th>Product Description</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Amount</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>Item Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in productDetails" :key="index">
                <td>
                  <v-select :clearable="false" v-model="item.productDescription" :options="productOptions"
                    label="description" :reduce="product => product.value" placeholder="Select Product"
                    class="input-box"></v-select>
                </td>
                <td><input v-model.number="item.quantity" type="number" class="input-box" /></td>
                <td><input v-model.number="item.unit" type="text" class="input-box" /></td>
                <td><input v-model.number="item.rate" type="number" class="input-box" @keyup.enter="addRow(item)" />
                </td>
                <td><input v-model.number="item.amount" type="text" readonly class="onlyread" /></td>
                <td><input v-model.number="item.cgst" type="text" readonly class="onlyread" /></td>
                <td><input v-model.number="item.sgst" type="text" readonly class="onlyread" /></td>
                <td><input v-model.number="item.igst" type="text" readonly class="onlyread" /></td>
                <td>{{ formatToTwoDecimal(calculateItemTotal(item)) }}</td>
                <td>
                  <button v-if="productDetails.length > 1" @click="removeRow(index)" class="delete-btn">
                    ✖
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Amount Summary Table -->
        <div class="table-section">
          <h4>Amount Summary</h4>
          <table class="summary-table">
            <thead>
              <tr>
                <th>Amount</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ formatToTwoDecimal(totalAmount) }}</td>
                <td>{{ formatToTwoDecimal(totalCGST) }}</td>
                <td>{{ formatToTwoDecimal(totalSGST) }}</td>
                <td>{{ formatToTwoDecimal(totalIGST) }}</td>
                <td>{{ formatToTwoDecimal(totalSummary) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Final Details -->
        <div class="row final-row">
          <div class="field">
            <label for="round-off-amount">Round Off:</label>
            <div id="round-off-amount" class="readonly-value">{{ formatToTwoDecimal(roundOffAmount) }}</div>
          </div>
          <div class="field">
            <label for="payable-amount">Payable Amount:</label>
            <div id="payable-amount" class="readonly-value">{{ formatToTwoDecimal(payableAmount) }}</div>
          </div>
        </div>

        <!-- Save Button -->
        <div class="save-button">
          <button @click="saveReport">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';

export default {
  components: {
    vSelect
  },
  data() {
    return {
      date: '',
      selectedApplicant: null,
      selectedAccount: null,
      customerRef: '',
      reportInBill: false,
      roundOffAmount: 0,
      particulars: '',
      reportDate: '',
      taxslab: 1,
      roundOffChecked: false, // Add this
      nonGstBill: false,
      isExpBill: false,
      productDetails: [{ productDescription: null, quantity: 0, unit: '', rate: 0, amount: 0, sgst: 0, cgst: 0, igst: 0 }],
      productOptions: [
        { description: 'Survey Fees', value: 'survey_fees' },
        { description: 'Travelling', value: 'travelling' },
        { description: 'Misc Expenses', value: 'misc_expenses' }
      ],
      accounts: JSON.parse(localStorage.getItem('accounts') || '[]'),
      applicants: JSON.parse(localStorage.getItem('applicants') || '[]'),
      MisDbList: [],
      MisDbListDtl: [],
      usercompany: []
    };
  },
  computed: {
    totalAmount() {
      return this.productDetails.reduce((sum, item) => sum + this.calculateAmount(item), 0);
    },
    totalCGST() {
      return this.productDetails.reduce((sum, item) => sum + item.cgst, 0);
    },
    totalSGST() {
      return this.productDetails.reduce((sum, item) => sum + item.sgst, 0);
    },
    totalIGST() {
      return this.productDetails.reduce((sum, item) => sum + item.igst, 0);
    },
    totalSummary() {
      return this.totalAmount + this.totalCGST + this.totalSGST + this.totalIGST;
    },
    payableAmount() {
      let amount = this.totalSummary;
      if (this.roundOffChecked) {
        amount = Math.round(amount);
      }
      return amount - this.roundOffAmount;
    }
  },
  methods: {
    async saveReport() {
      // Add your save logic here
      console.log('Report saved');
      this.MisDbList = [];
      const selectedParty = this.applicants.find(app => app.applicantid === Number(this.selectedApplicant));
      console.log(selectedParty)
      console.log(selectedParty.state_id)
      const misMast = {
        applicantID: this.selectedApplicant,
        accountID: this.selectedAccount,
        state_id: selectedParty.state_id,
        billdate: this.date,
        custrefno: this.customerRef,
        isReport: this.reportInBill,
        reportdate: this.reportInBill == false ? null : this.reportDate,
        isRound: this.roundOffChecked,
        particulars: this.particulars,
        taxslab: this.taxslab,
        isExpBill: this.isExpBill,
      };
      this.MisDbList.push(misMast);
      const mismaster = JSON.stringify(this.MisDbList)
      console.log(mismaster);

      const misdtls = JSON.stringify(this.MisDbListDtl)
      console.log(misdtls);
      const data = new FormData();
      data.append('mismaster', mismaster)
      data.append('misdetail', misdtls)
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the headers
        },
      };
      const response = await axios.post('/savemisbill', data, config);
      console.log(response);
      if (response.data[0] === 0) {
        // Display success message using the second element of the response
        useToast().error(response.data[1]);
      } else {
        useToast().success('Created Succesful');

        console.log(this.successfulTasks, 'hello')
      }

    },
    calculateAmount(item) {
      return item.quantity * item.rate;
    },
    calculateItemTotal(item) {
      return item.amount + item.cgst + item.sgst + item.igst;
    },
    calculateAllAmount(item) {
      console.log(this.usercompany);
      const selectedParty = this.applicants.find(app => app.applicantid === Number(this.selectedApplicant));
      console.log(selectedParty)
      console.log(selectedParty.state_id)
      item.amount = item.quantity * item.rate;
      item.igst = 0
      item.sgst = 0
      item.cgst = 0
      if (this.nonGstBill == false) {
        if (this.usercompany.state_id == selectedParty.state_id) {
          this.taxslab = 2
          item.cgst = item.amount * 0.09;
          item.sgst = item.amount * 0.09;
        } else {
          this.taxslab = 1
          item.igst = item.amount * 0.18;
        }
      }
    },
    addRow(item) {
      this.calculateAllAmount(item)
      const misDtl = {
        billitem: item.productDescription,
        qty: item.quantity,
        unit: item.unit,
        rate: item.rate,
        amount: item.amount,
        cgst: item.cgst,
        sgst: item.sgst,
        igst: item.igst
      };

      const index = this.MisDbListDtl.findIndex(misDtls => misDtls.billitem === misDtl.billitem)
      console.log(index);
      if (index !== -1) {
        this.MisDbListDtl.splice(index, 1, misDtl);
      } else {
        this.MisDbListDtl.push(misDtl);
      }

      this.productDetails.push({ productDescription: null, quantity: 0, unit: '', rate: 0, amount: 0, sgst: 0, cgst: 0, igst: 0 });
    },
    removeRow(index) {
      if (this.productDetails.length > 1) {
        this.productDetails.splice(index, 1);
      }
    },
    formatToTwoDecimal(value) {
      return value.toFixed(2);
    }
  }, created() {
    this.usercompany = JSON.parse(localStorage.getItem('user'));
  }
};
</script>

<style scoped>
.page-container {
  margin-left: 20px;
  /* Adjust as needed */
}

.name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.top-edit-section {
  border: 1px solid #ddd;
  padding: 20px;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.field {
  margin-right: 20px;
  flex: 1;
}

.first-row .field,
.second-row .field {
  flex: 1;
}

.checkbox-field {
  display: flex;
  align-items: center;
}

.center-checkbox {
  text-align: center;
  flex: 1;
}

.input-box,
.particulars-box {
  width: 100%;
}

.particulars-box {
  min-height: 100px;
}

.separator {
  margin: 20px 0;
}

.table-section {
  margin-top: 20px;
}

.details-table,
.summary-table {
  width: 100%;
  border-collapse: collapse;
}

.center-non {
  margin-left: 45%;
}

.details-table th,
.details-table td,
.summary-table th,
.summary-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.delete-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.readonly-value {
  font-weight: bold;
}

.save-button {
  margin-top: 20px;
  text-align: center;
}

.save-button button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-button button:hover {
  background-color: #0056b3;
}

/* Additional styles for proper alignment */
.final-row {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  /* Align the fields vertically */
  align-items: flex-end;
  /* Align fields to the right side */
  gap: 20px;
  /* Space between fields */
}

.field {
  display: flex;
  flex-direction: row;
  /* Align label and value horizontally */
  align-items: center;
  /* Center items vertically */
}

.field label {
  margin-right: 10px;
  /* Space between label and value */
}

.readonly-value {
  font-size: 16px;
  /* Adjust font size as needed */
  font-weight: bold;
  /* Optional: Make the value bold */
}

.onlyread {
  width: 100px;
  background: transparent;
}
</style>
