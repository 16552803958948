<template>
    <div class="body-content">
        <h1 class="title">Dashboard</h1>
        <div>
            <h1>Image Gallery</h1>
            <button @click="fetchImages">Load Images</button>
            <div class="image-gallery">
                <img v-for="(image, index) in images" :key="index" :src="image" alt="Image" />
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
export default {

    data() {
        return {
            images: []
        };
    },
    methods: {
        async fetchImages() {
            /*const token = localStorage.getItem('access_token');
            const config = {
              headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            };*/
            const imageRes = await axios.get('/multiFileResponce?reportid=76');
            console.log(imageRes)

            //const imagePaths = ['path/to/image1.jpg', 'path/to/image2.jpg']; // Update with your image paths
            //const response = await fetch(/multiFileResponce/?image_paths=${imagePaths.join(',')});
            const files = await imageRes.json();
            this.images = files.map(file => file.url); // Adjust based on your API response structure
        }
    }
};
</script>

<style>
.image-gallery {
    display: flex;
    flex-wrap: wrap;
}

.image-gallery img {
    width: 200px;
    /* Adjust size as needed */
    margin: 10px;
}
</style>