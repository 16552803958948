<template>
  <div class="port-page">
    <div class="controls">
      <div class="search-container">
        <input type="text" v-model="searchQuery" placeholder="Search..." />
        <span class="search-icon">&#128269;</span>
      </div>
    </div>
    <div class="table-container">
      <table class="port-table">
        <thead>
          <tr>
            <th>Report No.</th>
            <th>Report Date</th>
            <th>Bill No.</th>
            <th>Applicant</th>
            <th>Account</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in paginatedReports" :key="report.rptno">
            <td>{{ report.rptno || 'N/A' }}</td>

            <td>{{ formatDate(report.rptdate) }}</td>
            <td>{{ report.billno }}</td>
            <td>{{ report.applicantname }}</td>
            <td>{{ report.accountname }}</td>
            <td>
              <button @click="openPDF(report.filename)" class="pdf-button">
                <i class="fas fa-file-pdf"></i> View PDF
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bottom-controls">
      <div class="rows-per-table">
        <button v-for="count in [10, 20, 50]" :key="count"
          :class="{ 'rows-per-table-button': true, 'active': rowsPerPage === count }" @click="changeRowsPerPage(count)">
          {{ count }}
        </button>
      </div>
      <div class="pagination">
        <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">&laquo;</button>
        <button v-for="page in totalPages" :key="page" :class="{ 'active': page === currentPage }"
          @click="changePage(page)">
          {{ page }}
        </button>
        <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">&raquo;</button>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
export default {
  data() {
    return {
      reports: [],
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1
    };
  },
  computed: {
    filteredReports() {
      const query = this.searchQuery.toLowerCase();
      return this.reports.filter(report => {
        return Object.values(report).some(value =>
          String(value).toLowerCase().includes(query)
        );
      });
    },
    totalPages() {
      return Math.ceil(this.filteredReports.length / this.rowsPerPage);
    },
    paginatedReports() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredReports.slice(start, end);
    }
  },
  methods: {
    async fetchReports() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/all_reports', {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        this.reports = response.data[2];
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
    },
    async openPDF(filename) {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      const response = await axios.get('/ConvertImageToBlob?filename=' + filename, config,{responseType:'blob'});
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(pdfBlob);
      window.open(url);
    },
  },
  mounted() {
    this.fetchReports();
  }
};
</script>



<style scoped>
.port-page {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.add-button,
.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover,
.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: block;
  position: absolute;
  top: 25px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 4px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.port-table {
  width: 100%;
  border-collapse: collapse;
}

.port-table th,
.port-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.port-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table-button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table-button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  display: inline-block;
}
</style>
